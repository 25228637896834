<template>
  <div v-if="user">
    <div
      class="mt-4 w-100 px-2"
      v-if="user"
      :class="{
        'dashboard-container':
          user.groups.includes(6) || user.groups.includes(7),
        'notification-dashboard':
          !user.groups.includes(6) && !user.groups.includes(7),
      }"
    >
      <!-- TODO: Comentado hasta Terminar trabajo en Evaluaciones. -->
      <!-- :class="{ 'dashboard-container': user.is_professor || user.is_student }" -->
      <div class="left-container">
        <!-- -------------------------Proximas Evaluaciones Profesor------------------------- -->
        <NextTestTeacher :key="key_notification"></NextTestTeacher>
        <!-- -------------------------Proximas Evaluaciones Estudiante------------------------- -->
        <NextTestStudent></NextTestStudent>
        <!-- -------------------------Evaluaciones Terminadas------------------------- -->
        <PublishTestStudent></PublishTestStudent>
        <!-- -------------------------Evaluaciones para corregir Profesor------------------------- -->
        <InstrumentCorrection></InstrumentCorrection>
        <!-- -------------------------Evaluaciones "Mis Clases" Profesor------------------------- -->
        <CurrentSectionTeacher></CurrentSectionTeacher>
        <!-- -------------------------Evaluaciones "Mis Clases" Estudiante------------------------- -->
        <CurrentSectionStudent></CurrentSectionStudent>
      </div>

      <div v-if="my_class.length > 0" class="left-container mr-3">
        <Card
          class="dashboard-card"
          v-if="user.is_student || user.is_professor"
          :variant_fifth="true"
        >
          <template v-slot:title>
            <b-icon class="card-header-icon" icon="calendar3"></b-icon>
            Evaluaciones en los Próximos 7 Días</template
          >
          <template v-slot:content>
            <div v-if="my_class.pending_instruments">
              <div
                class="text-left"
                v-if="my_class.pending_instruments.length == 0"
              >
                No tiene evaluaciones asignadas dentro de los últimos 7 días.
              </div>
              <div
                class="text-left d-flex"
                v-for="item in my_class.pending_instruments"
                :key="item.id"
              >
                <b-icon icon="chevron-right" class="mr-1"></b-icon>
                <span class="text-class w-25">
                  <strong>Fecha de Inicio:</strong>
                  {{ item.start_date | FormatToDateTime }}</span
                >
                <span class="text-class w-25"
                  ><strong>Fecha de Término:</strong>
                  {{ item.due_date | FormatToDateTime }}</span
                >
                <span
                  class="w-25 text-class text-break"
                  @click="(e) => e.target.classList.toggle('text-break')"
                  v-b-tooltip.v-secondary.noninteractive.hover="`${item.title}`"
                  >{{ item.title }}</span
                >
                <span
                  class="w-25 text-class text-break"
                  v-b-tooltip.v-secondary.noninteractive.hover="
                    `${
                      item.section_names.length > 1
                        ? $getVisibleNames(
                            'teaching.section',
                            true,
                            'Secciones'
                          )
                        : item.section_names.length == 1
                        ? String(
                            item.section_names[0].name +
                              ' - ' +
                              item.section_names[0]
                                .egress_profile_matter__matter__name
                          )
                        : ' -'
                    }`
                  "
                  @click="(e) => e.target.classList.toggle('text-break')"
                  :id="`popover-pending_instruments-section-${item.id}`"
                  >{{
                    item.section_names.length > 1
                      ? $getVisibleNames("teaching.section", true, "Secciones")
                      : item.section_names.length == 1
                      ? String(
                          item.section_names[0].name +
                            " - " +
                            item.section_names[0]
                              .egress_profile_matter__matter__name
                        )
                      : " -"
                  }}
                </span>
                <b-icon
                  :id="`popover-pending_instruments-evaluation-${item.id}`"
                  class="ml-1 card-header-icon"
                  icon="three-dots"
                ></b-icon>
                <b-popover
                  v-if="item.section_names.length > 1"
                  :target="`popover-pending_instruments-section-${item.id}`"
                  triggers="hover"
                >
                  <template #title>{{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      true,
                      "Asignaturas"
                    )
                  }}</template>
                  <div v-for="section in item.section_names" :key="section.id">
                    <div>
                      {{ section.name }} -
                      {{ section.egress_profile_matter__matter__name }}
                    </div>
                  </div>
                </b-popover>
                <b-popover
                  :target="`popover-pending_instruments-evaluation-${item.id}`"
                  triggers="hover"
                >
                  <b-button
                    v-if="user.is_student || user.is_professor"
                    variant="none"
                    @click="
                      redirectToEvaluation(
                        item.evaluation_id,
                        item.id,
                        item.type
                      )
                    "
                    class="profiles mb-1 text-white"
                  >
                    Ir a la Evaluación</b-button
                  ></b-popover
                >
              </div>
            </div>
          </template>
        </Card>
        <div
          v-if="
            my_class.current_sections && (user.is_professor || user.is_student)
          "
        >
          <Card class="dashboard-card" :variant_primary="true">
            <template v-slot:title>
              <b-icon class="card-header-icon" icon="journal-bookmark"></b-icon>
              Mis {{ $getVisibleNames("manual.classrooms", true, "Clases") }}
              {{ "- " + getPeriod }}</template
            >
            <template v-slot:content>
              <div
                class="text-left"
                v-if="my_class.current_sections.length == 0"
              >
                No cuenta con ninguna
                {{ $getVisibleNames("teaching.section", false, "Sección") }}
                asignada en el periodo actual.
              </div>
              <div v-else>
                <div class="text-left" v-if="user.is_student">
                  Cursos en los que eres estudiante:
                </div>
                <div class="text-left" v-else-if="user.is_professor">
                  Cursos en los que eres docente:
                </div>
                <div
                  class="text-left d-flex"
                  v-for="item in my_class.current_sections"
                  :key="item.id"
                >
                  <!-- :id="`popover-matter-section-${item.id}`" -->
                  <b-icon icon="bookmark" class="bookmark-icon mr-1"></b-icon
                  ><span
                    class="text-class w-25 text-break"
                    @click="(e) => e.target.classList.toggle('text-break')"
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      `Nivel ${item.plan_level} - ${item.matter_name}`
                    "
                    >Nivel {{ item.plan_level }} - {{ item.matter_name }}</span
                  >
                  <span
                    :id="`popover-name-section-${item.id}`"
                    class="text-class w-25 text-break"
                    @click="(e) => e.target.classList.toggle('text-break')"
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      `${$getVisibleNames(
                        'teaching.section',
                        false,
                        'Sección'
                      )}: ${item.name}`
                    "
                    >{{ item.name }}</span
                  >
                  <span
                    :id="`popover-professors-section-${item.id}`"
                    class="w-25 text-class"
                  >
                    <span class="text-class">
                      {{
                        item.professors.length > 1
                          ? "Docentes"
                          : item.professors.length == 1
                          ? String(
                              item.professors[0].first_name +
                                " " +
                                item.professors[0].last_name
                            )
                          : " -"
                      }}
                      <template
                        v-if="
                          item.professors.length == 1 &&
                          item.professors[0].email
                        "
                      >
                        <b-link
                          :href="`mailto:${item.professors[0].email}`"
                          v-b-tooltip.v-secondary.noninteractive.hover.right="
                            item.professors[0].email
                          "
                          class="mr-2"
                          ><b-icon icon="envelope" animation="pulse"></b-icon
                        ></b-link>
                      </template>
                    </span>
                  </span>
                  <span
                    class="text-class w-25 text-break"
                    @click="(e) => e.target.classList.toggle('text-break')"
                    v-if="user.is_professor"
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      `${item.egress_profile_professional_title}`
                    "
                  >
                    {{ item.egress_profile_professional_title }}
                  </span>
                  <b-icon
                    :id="`popover-matter-section-${item.id}`"
                    class="ml-1 card-header-icon"
                    icon="three-dots"
                  ></b-icon>
                  <b-popover
                    :target="`popover-matter-section-${item.id}`"
                    triggers="hover"
                  >
                    <!-- <template #title>Asignatura</template> -->
                    <b-button
                      variant="none"
                      @click="
                        redirectToMatterProgram(item.egress_profile_matter_id)
                      "
                      class="profiles mb-1 text-white"
                    >
                      {{
                        $getVisibleNames(
                          "manual.matter_program",
                          false,
                          "Programa de la Asignatura"
                        )
                      }}</b-button
                    >
                    <!-- institution.id != 12 Para ocultar la planificación de la institución Duoc -->
                    <b-button
                      v-if="
                        institution && institution.internal_use_id != 'duoc_uc'
                      "
                      variant="none"
                      @click="
                        redirectToSessionPlanning(
                          item.egress_profile_matter_id,
                          item.id
                        )
                      "
                      class="profiles mb-1 text-white"
                    >
                      {{
                        $getVisibleNames(
                          "teaching.mattersessionplanning",
                          false,
                          "Planificación de la Asignatura"
                        )
                      }}</b-button
                    >
                    <b-button
                      v-if="user.is_professor || user.is_student"
                      variant="none"
                      @click="
                        redirectToEvaluationTable(item.egress_profile_matter_id)
                      "
                      class="profiles mb-1 text-white"
                    >
                      Evaluaciones</b-button
                    >
                    <b-button
                      v-if="user.is_professor"
                      variant="none"
                      @click="redirectToNeedsGrading(item.id)"
                      class="profiles mb-1 text-white"
                    >
                      Centro de Calificaciones</b-button
                    >
                    <b-button
                      v-if="user.is_professor"
                      variant="none"
                      @click="redirectToFullGrading(item.id)"
                      class="profiles mb-1 text-white"
                    >
                      Notas</b-button
                    >
                    <b-button
                      variant="none"
                      @click="redirectToAssessment(item.id)"
                      class="profiles mb-1 text-white"
                    >
                      Assessment por Estudiante</b-button
                    >
                    <b-button
                      variant="none"
                      v-if="user.is_professor"
                      @click="redirectToAssessmentReport(item.id)"
                      class="profiles mb-1 text-white"
                    >
                      Reporte de Assessment</b-button
                    >
                    <!-- <b-button
                      variant="none"
                      @click="redirectToAchievementsPerCompetence()"
                      class="profiles mb-1 text-white"
                      v-if="user.is_student"
                    >
                      Progresión de Competencias</b-button
                    > -->
                  </b-popover>
                  <b-popover
                    v-if="item.professors.length > 1"
                    :target="`popover-professors-section-${item.id}`"
                    triggers="hover"
                  >
                    <template #title>Docentes</template>
                    <div
                      v-for="professor in item.professors"
                      :key="professor.id"
                    >
                      <div
                        v-b-tooltip.v-secondary.noninteractive.hover.right="
                          professor.email
                        "
                      >
                        {{ professor.first_name }} {{ professor.last_name }}
                        <template v-if="professor.email">
                          <b-link
                            :key="`icon-${professor.id}`"
                            :href="`mailto:${professor.email}`"
                            class="mr-2"
                            ><b-icon icon="envelope" animation="pulse"></b-icon
                          ></b-link>
                        </template>
                      </div>
                    </div>
                  </b-popover>
                </div>
              </div>
            </template>
          </Card>
        </div>
        <Card
          class="dashboard-card"
          :variant_primary="true"
          v-if="user.is_student"
        >
          <template v-slot:title>
            <div class="d-flex">
              <div class="w-75 mt-2">
                <dir>
                  <b-icon
                    class="card-header-icon"
                    icon="journal-bookmark"
                  ></b-icon>
                  Notas
                </dir>
              </div>
              <div class="w-25"></div>
              <b-button
                v-if="user.student_card == null"
                @click="patchStudentCard"
                >Ocultar</b-button
              >
              <b-button
                @click="patchStudentCard"
                v-else-if="user.student_card.show_dashboard_scores"
                >Mostrar</b-button
              >
              <b-button @click="patchStudentCard" v-else>Ocultar</b-button>
            </div>
          </template>
          <template v-slot:content>
            <div>
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  style="background: rgba(153, 201, 216, 0.3)"
                >
                  <div class="div-href" @click="modalSectionGrades(true)">
                    Actuales:
                  </div>
                  <b-badge
                    variant="primary"
                    pill
                    v-b-tooltip.v-secondary.noninteractive="
                      'Promedio de notas del periodo actual'
                    "
                    ><span v-if="user.student_card == null">{{
                      my_class.current_section_grades_avg
                        ? my_class.current_section_grades_avg.toFixed(1)
                        : my_class.current_section_grades_avg
                    }}</span>
                    <span
                      v-else-if="!user.student_card.show_dashboard_scores"
                      >{{
                        my_class.current_section_grades_avg
                          ? my_class.current_section_grades_avg.toFixed(1)
                          : my_class.current_section_grades_avg
                      }}</span
                    >
                    <span v-else>-</span></b-badge
                  >
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  style="background: rgba(153, 201, 216, 0.3)"
                >
                  <div class="div-href" @click="modalSectionGrades(false)">
                    Completadas:
                  </div>
                  <b-badge
                    variant="primary"
                    pill
                    v-b-tooltip.v-secondary.noninteractive="
                      `
                      Promedio de notas de ${$getVisibleNames(
                        'mesh.egressprofilematter',
                        true,
                        'Asignaturas'
                      )} cursadas`
                    "
                    ><span v-if="user.student_card == null">{{
                      my_class.past_section_grades_avg
                        ? my_class.past_section_grades_avg.toFixed(1)
                        : my_class.past_section_grades_avg
                    }}</span>
                    <span
                      v-else-if="!user.student_card.show_dashboard_scores"
                      >{{
                        my_class.past_section_grades_avg
                          ? my_class.past_section_grades_avg.toFixed(1)
                          : my_class.past_section_grades_avg
                      }}</span
                    >
                    <span v-else>-</span></b-badge
                  >
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>
        </Card>
        <Card
          class="dashboard-card"
          :variant_thirth="true"
          v-if="user && user.is_student"
        >
          <template v-slot:title
            ><b-icon class="card-header-icon" icon="bookmark-star"></b-icon>
            Logros</template
          >
          <template v-slot:content>
            <b-button
              variant="none"
              v-if="user && user.is_student"
              class="button-achievements"
              @click="redirectToAchievementsPerCompetence()"
            >
              <span class="circle" aria-hidden="true">
                <span class="icon arrow"></span>
              </span>
              <span class="button-text"
                >Progresión de
                {{
                  $getVisibleNames("mesh.competence", true, "Competencias")
                }}</span
              >
            </b-button>
          </template>
        </Card>
        <div
          v-if="
            my_class.past_sections && (user.is_professor || user.is_student)
          "
        >
          <Card class="dashboard-card" :variant_primary="true">
            <template v-slot:title>
              <b-icon class="card-header-icon" icon="journal-bookmark"></b-icon>
              {{ $getVisibleNames("manual.classrooms", true, "Clases") }}
              Cursadas
            </template>
            <template v-slot:content>
              <div class="text-left" v-if="my_class.past_sections.length == 0">
                No cuenta con ninguna
                {{ $getVisibleNames("teaching.section", false, "Sección") }}
                cursada.
              </div>
              <div else>
                <div class="text-left" v-if="user.is_student">
                  Cursos en los que fuiste estudiante:
                </div>
                <div class="text-left" v-else-if="user.is_professor">
                  Cursos en los que fuiste docente:
                </div>
                <BoundedItemList
                  class="text-left"
                  :ArrayList="my_class.past_sections"
                  :ShowNumberList="3"
                >
                  <template v-slot:item="{ item }">
                    <div class="d-flex">
                      <b-icon
                        icon="bookmark"
                        class="bookmark-icon mr-1"
                      ></b-icon
                      ><span
                        class="text-class w-25 text-break"
                        @click="(e) => e.target.classList.toggle('text-break')"
                        v-b-tooltip.v-secondary.noninteractive.hover="
                          `Nivel ${item.plan_level} - ${item.matter_name}`
                        "
                        >Nivel {{ item.plan_level }} - {{ item.matter_name }}
                      </span>
                      <span
                        :id="`popover-name-past-section-${item.id}`"
                        class="text-class w-25 text-break"
                        @click="(e) => e.target.classList.toggle('text-break')"
                        v-b-tooltip.v-secondary.noninteractive.hover="
                          `${$getVisibleNames(
                            'teaching.section',
                            false,
                            'Sección'
                          )}: ${item.name} - ${item.period_name}`
                        "
                        >{{ item.name }} - {{ item.period_name }}</span
                      >
                      <span
                        :id="`popover-professors-past-section-${item.id}`"
                        class="w-25 text-class"
                      >
                        <span class="text-class">
                          {{
                            item.professors.length > 1
                              ? "Docentes"
                              : item.professors.length == 1
                              ? String(
                                  item.professors[0].first_name +
                                    " " +
                                    item.professors[0].last_name
                                )
                              : " -"
                          }}
                          <template
                            v-if="
                              item.professors.length == 1 &&
                              item.professors[0].email
                            "
                          >
                            <b-link
                              :href="`mailto:${item.professors[0].email}`"
                              v-b-tooltip.v-secondary.noninteractive.hover.right="
                                item.professors[0].email
                              "
                              class="mr-2"
                              ><b-icon
                                icon="envelope"
                                animation="pulse"
                              ></b-icon
                            ></b-link>
                          </template>
                        </span>
                      </span>
                      <span
                        class="text-class w-25 text-break"
                        @click="(e) => e.target.classList.toggle('text-break')"
                        v-if="user.is_professor"
                        v-b-tooltip.v-secondary.noninteractive.hover="
                          `${item.egress_profile_professional_title}`
                        "
                      >
                        {{ item.egress_profile_professional_title }}
                      </span>
                      <b-icon
                        :id="`popover-matter-past-section-${item.id}`"
                        class="ml-1 card-header-icon"
                        icon="three-dots"
                      ></b-icon>
                      <b-popover
                        :target="`popover-matter-past-section-${item.id}`"
                        triggers="hover"
                      >
                        <!-- <template #title>Asignatura</template> -->
                        <b-button
                          variant="none"
                          @click="
                            redirectToMatterProgram(
                              item.egress_profile_matter_id
                            )
                          "
                          class="profiles mb-1 text-white"
                        >
                          {{
                            $getVisibleNames(
                              "manual.matter_program",
                              false,
                              "Programa de la Asignatura"
                            )
                          }}</b-button
                        >
                        <!-- institution.id != 12 Para ocultar la planificación de la institución Duoc -->
                        <b-button
                          v-if="
                            institution &&
                            institution.internal_use_id != 'duoc_uc'
                          "
                          variant="none"
                          @click="
                            redirectToSessionPlanning(
                              item.egress_profile_matter_id,
                              item.id
                            )
                          "
                          class="profiles mb-1 text-white"
                        >
                          {{
                            $getVisibleNames(
                              "teaching.mattersessionplanning",
                              false,
                              "Planificación de la Asignatura"
                            )
                          }}</b-button
                        >
                        <!-- <b-button
                          v-if="user.is_professor"
                          variant="none"
                          @click="redirectToNeedsGrading(item.id)"
                          class="profiles mb-1 text-white"
                        >
                          Ir a evaluar</b-button
                        >  -->
                        <b-button
                          v-if="user.is_professor"
                          variant="none"
                          @click="
                            redirectToEvaluationTable(
                              item.egress_profile_matter_id
                            )
                          "
                          class="profiles mb-1 text-white"
                        >
                          Evaluaciones</b-button
                        >
                        <b-button
                          v-if="user.is_professor"
                          variant="none"
                          @click="redirectToFullGrading(item.id)"
                          class="profiles mb-1 text-white"
                        >
                          Notas</b-button
                        >
                        <b-button
                          variant="none"
                          @click="redirectToAssessment(item.id)"
                          class="profiles mb-1 text-white"
                        >
                          Assessment por Estudiante</b-button
                        >
                        <b-button
                          variant="none"
                          v-if="user.is_professor"
                          @click="redirectToAssessmentReport(item.id)"
                          class="profiles mb-1 text-white"
                        >
                          Reporte de Assessment</b-button
                        >
                        <!-- <b-button
                          variant="none"
                          @click="redirectToAchievementsPerCompetence()"
                          class="profiles mb-1 text-white"
                          v-if="user && user.is_student"
                        >
                          Progresión de Competencias</b-button
                        > -->
                      </b-popover>
                      <b-popover
                        :target="`popover-professors-past-section-${item.id}`"
                        v-if="item.professors.length > 1"
                        triggers="hover"
                      >
                        <template #title>{{
                          item.professors.length > 1 ? "Docentes" : "Docente"
                        }}</template>
                        <div
                          v-for="professor in item.professors"
                          :key="professor.id"
                        >
                          <div
                            v-b-tooltip.v-secondary.noninteractive.hover.right="
                              professor.email
                            "
                          >
                            {{ professor.first_name }}
                            {{ professor.last_name }}
                            <template v-if="professor.email">
                              <b-link
                                :key="`icon-${professor.id}`"
                                :href="`mailto:${professor.email}`"
                                class="mr-2"
                                ><b-icon
                                  icon="envelope"
                                  animation="pulse"
                                ></b-icon
                              ></b-link>
                            </template>
                          </div>
                        </div>
                      </b-popover>
                    </div>
                  </template>
                </BoundedItemList>
              </div>
            </template>
          </Card>
        </div>
      </div>
      <!-- TODO: Comentado hasta Terminar trabajo en Evaluaciones. -->
      <!-- :class="{ 'right-container': user.is_professor || user.is_student }" -->
      <div
        :class="{
          'ml-2 right-container':
            user.groups.includes(6) || user.groups.includes(7),
        }"
      >
        <!-- -------------------------Notificaciones------------------------- -->
        <Card class="dashboard-card" :variant_thirth="true">
          <template v-slot:title>
            <b-icon class="card-header-icon" icon="bell"></b-icon>
            Atención/Notificaciones
          </template>
          <template v-slot:content>
            <!-- Template si tiene evaluaciones sin fecha por definir -->
            <template
              v-if="
                user.groups.includes(6) &&
                next_evaluation_teacher_notification.length > 0
              "
            >
              <div class="text-left d-flex">
                <div>⚠️</div>
                <template
                  v-if="next_evaluation_teacher_notification.length > 1"
                >
                  <div class="notification-text">
                    Tiene
                    <strong
                      class="div-href-test"
                      @click="$bvModal.show(`notification-test-without-time`)"
                    >
                      {{ next_evaluation_teacher_notification.length }}
                      {{
                        $getVisibleNames(
                          "evaluations2.test",
                          true,
                          "Instrumentos"
                        )
                      }}
                    </strong>
                    sin fechas asignada.
                  </div>
                </template>
                <template v-else>
                  <div class="notification-text">
                    Tiene
                    <strong
                      class="div-href-test"
                      @click="$bvModal.show(`notification-test-without-time`)"
                    >
                      {{ next_evaluation_teacher_notification.length }}
                      {{
                        $getVisibleNames(
                          "evaluations2.test",
                          false,
                          "Instrumento"
                        )
                      }}
                    </strong>
                    sin fecha asignada.
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="text-left">No tiene notificaciones...</div>
            </template>
            <b-modal
              :id="`notification-test-without-time`"
              :hide-footer="true"
              :title="`Asignar fechas a las ${$getVisibleNames(
                'evaluations2.evaluation',
                true,
                'Evaluaciones'
              )} restantes`"
              size="xl"
            >
              <template>
                <div
                  v-if="
                    next_evaluation_teacher_notification.length != 0 ||
                    optionsMatterNotificationTest.length > 1 ||
                    optionsCareerNotificationTest.length > 1 ||
                    optionsSectionNotificationTest.length > 1 ||
                    optionsCampuNotificationTest.length > 1
                  "
                  class="container-select mb-2"
                >
                  <div>
                    <b-form-select
                      id="input-campus"
                      class="select-form"
                      v-model="select_campus_notification_test"
                      :options="optionsCampuNotificationTest"
                    ></b-form-select>
                  </div>
                  <div>
                    <b-form-select
                      id="input-career"
                      class="select-form"
                      v-model="select_career_notification_test"
                      :options="optionsCareerNotificationTest"
                    ></b-form-select>
                  </div>
                  <div>
                    <b-form-select
                      id="input-matter"
                      class="select-form"
                      v-model="select_matter_notification_test"
                      :options="optionsMatterNotificationTest"
                    ></b-form-select>
                  </div>
                  <div>
                    <b-form-select
                      id="input-section"
                      class="select-form"
                      v-model="select_section_correct_test"
                      :options="optionsSectionNotificationTest"
                    ></b-form-select>
                  </div>
                </div>
                <b-table-simple hover sticky-header="500px">
                  <b-thead>
                    <b-tr>
                      <!-- Columna Nombre Evaluacion (Evaluacion)-->
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 11%"
                      >
                        {{
                          $getVisibleNames(
                            "evaluations2.evaluation",
                            false,
                            "Evaluación"
                          )
                        }}
                      </b-th>
                      <!-- Columna Nombre Evaluacion (Test) -->
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 11%"
                      >
                        <!-- {{
                        $getVisibleNames(
                          "teaching.evaluationsituation",
                          false,
                          "Situación Evaluativa"
                        )
                      }} -->
                        {{
                          $getVisibleNames(
                            "evaluations2.test",
                            false,
                            "Instrumento"
                          )
                        }}
                      </b-th>
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 9%"
                      >
                        {{ $getVisibleNames("mesh.campus", false, "Sede") }}
                      </b-th>
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 11%"
                      >
                        {{ $getVisibleNames("mesh.career", false, "Programa") }}
                      </b-th>
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 11%"
                        >{{
                          $getVisibleNames(
                            "mesh.egressprofilematter",
                            false,
                            "Asignatura"
                          )
                        }}</b-th
                      >
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 7%"
                      >
                        {{
                          $getVisibleNames("teaching.section", false, "Sección")
                        }}
                      </b-th>
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 5%"
                        >Fecha Inicio</b-th
                      >
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 5%"
                        >Fecha Término</b-th
                      >
                      <b-th
                        class="primary-cell-next-test-notification text-center"
                        style="width: 5%"
                      >
                        Editar Fechas
                      </b-th>
                    </b-tr>
                  </b-thead>

                  <b-tbody>
                    <template v-for="test in filterNextEvaluationNotification">
                      <b-tr :key="test.id">
                        <!-- Nombre de la Evaluacion (Evaluacion) -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          <div class="mr-0">
                            {{ test.evaluation_title }}
                          </div>
                        </b-td>
                        <!-- Nombre de la Evaluacion (Test) -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          <span
                            class="div-href-test"
                            @click="
                              redirectToTestTeacher(test.evaluation_id, test.id)
                            "
                            v-b-tooltip.v-secondary.top.noninteractive="
                              `Ir a la ${$getVisibleNames(
                                'evaluations2.evaluation',
                                false,
                                'Evaluación'
                              )}`
                            "
                          >
                            {{ test.title }}
                          </span>
                        </b-td>
                        <!-- Sede -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          {{ test.campus }}
                        </b-td>
                        <!-- Carrera -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          {{ test.career_name }}
                        </b-td>
                        <!-- Asignatura -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          {{ test.matter_code }} - {{ test.matter_name }}
                        </b-td>
                        <!-- Seccion -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          {{ test.section_name }}
                        </b-td>
                        <!-- Fecha de Inicio -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          <template v-if="test.start_time">
                            {{ test.start_time | FormatToDateTime }}
                          </template>
                          <template v-else> N/A </template>
                        </b-td>
                        <!-- Fecha de Termino -->
                        <b-td
                          class="secondary-cell-next-test-notification text-left"
                        >
                          <template v-if="test.end_time">
                            {{ test.end_time | FormatToDateTime }}
                          </template>
                          <template v-else> N/A </template>
                        </b-td>
                        <!-- Opciones -->
                        <b-td class="secondary-cell-next-test-notification">
                          <!-- Botón 3 puntos que abre popover -->
                          <div>
                            <button-edit
                              variant="secondary"
                              v-b-tooltip.v-secondary.left.noninteractive="
                                'Editar Fecha de Inicio y Fecha de Término'
                              "
                              @click="
                                $bvModal.show(
                                  `modal-edit-instrument-${test.id}`
                                )
                              "
                            ></button-edit>
                          </div>
                          <b-modal
                            :id="`modal-edit-instrument-${test.id}`"
                            :title="`Editar ${$getVisibleNames(
                              'evaluations2.test',
                              false,
                              'Instrumento'
                            )}`"
                            hide-footer
                            size="xl"
                          >
                            <NewInstrumentForm
                              :evaluation_id="test.evaluation_id"
                              :locked_instrument="test.is_locked"
                              :Instrument="
                                new_tests.find((x) => x.id == test.id)
                              "
                              @closeModal="changeInstrumentForm(test)"
                            ></NewInstrumentForm>
                          </b-modal>
                        </b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </template>
            </b-modal>
          </template>
        </Card>
        <Card class="dashboard-card" :variant_fourth="true">
          <template v-slot:title
            ><b-icon class="card-header-icon" icon="volume-up"></b-icon>
            Anuncios</template
          >
          <template v-slot:content>
            <div class="text-left">
              Ningún anuncio de la institución en los últimos 7 días.
            </div>
          </template>
        </Card>
        <Card class="dashboard-card" :variant_primary="true">
          <template v-slot:title
            ><b-icon class="card-header-icon" icon="chat-left-text"></b-icon>
            Mensajes</template
          >
          <template v-slot:content>
            <div class="text-left">Ningún mensaje en los últimos 7 días.</div>
          </template>
        </Card>
        <Card class="dashboard-card" :variant_secondary="true">
          <template v-slot:title
            ><b-icon class="card-header-icon" icon="tools"></b-icon>
            Recursos</template
          >
          <template v-slot:content>
            <div class="text-left">No cuenta con recursos asignados.</div>
            <!-- <div class="text-left">
            <div>
              <b-icon icon="chevron-right" class="mr-1"></b-icon>
              <a href="http://" target="_blank" rel="noopener noreferrer"
                >Moodle
              </a>
            </div>
            <div>
              <b-icon icon="chevron-right" class="mr-1"></b-icon>
              <a href="http://" target="_blank" rel="noopener noreferrer"
                >Biblioteca
              </a>
            </div>
          </div> -->
          </template>
        </Card>
      </div>
    </div>
    <!-- Modal -->
    <b-modal id="modal-assessment" size="xl" hide-footer>
      <template #modal-title> Assessment </template>
      <div>
        <AssessmentTable :user_id="user.id"></AssessmentTable>
      </div>
    </b-modal>
    <b-modal id="modal-history-evaluation" size="xl" hide-footer>
      <template #modal-title>
        Historial de Evaluaciones {{ modal_header }}</template
      >
      <div class="d-block text-center">
        <b-table-simple bordered small responsive>
          <b-thead>
            <b-tr>
              <b-th class="th-class">{{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}</b-th>
              <b-th class="th-class">Nota</b-th>
              <b-th class="th-class">Evaluación</b-th>
              <b-th class="th-class">
                Nota
                <InfoTooltip
                  :tooltip_text="`En esta columna muestra la nota de la evaluación con respecto a la ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}.`"
                ></InfoTooltip>
              </b-th>
              <b-th class="th-class">Puntaje Obtenido</b-th>
              <b-th class="th-class">
                Ponderación
                <InfoTooltip
                  :tooltip_text="`Esta ponderación muestra cuanto pondera esta evaluación con respecto a la ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}.`"
                ></InfoTooltip>
              </b-th>
              <b-th class="th-class">Intrumento</b-th>
              <b-th class="th-class">
                Nota
                <InfoTooltip
                  tooltip_text="En esta columna muestra la nota del instrumento con respecto a la Evaluación."
                ></InfoTooltip>
              </b-th>
              <b-th class="th-class">Puntaje Obtenido</b-th>
              <b-th class="th-class">
                Ponderación
                <InfoTooltip
                  tooltip_text="Esta ponderación muestra cuanto pondera el instrumento con respecto a la evaluación."
                ></InfoTooltip>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="my_evaluation_scores.length > 0">
              <template v-for="(matter, index) in my_evaluation_scores">
                <template v-if="matter.evaluations.length > 0">
                  <template v-for="(evaluation, index2) in matter.evaluations">
                    <template v-if="evaluation.instruments.length > 0">
                      <b-tr
                        v-for="(instrument, index3) in evaluation.instruments"
                        :key="
                          'Matter:' +
                          index +
                          'Evaluation:' +
                          index2 +
                          'Instrument:' +
                          index3
                        "
                      >
                        <b-td
                          class="td-class"
                          :rowspan="rowspanCount(matter)"
                          v-if="index2 == 0 && index3 == 0"
                        >
                          <div
                            class="div-href"
                            @click="
                              redirectToMatterProgram(
                                matter.egress_profile_matter_id
                              )
                            "
                          >
                            {{ matter.matter_name }}
                          </div>
                        </b-td>
                        <b-td
                          class="td-class"
                          :rowspan="rowspanCount(matter)"
                          v-if="index2 == 0 && index3 == 0"
                          v-bind:class="
                            matter.section_grade.toFixed(1) == 0
                              ? ''
                              : matter.section_grade.toFixed(1) <= 3.9
                              ? 'bg-red'
                              : matter.section_grade.toFixed(1) <= 5.4
                              ? 'bg-yellow'
                              : 'bg-green'
                          "
                          >{{ matter.section_grade.toFixed(1) }}</b-td
                        >
                        <b-td
                          class="td-class"
                          :rowspan="evaluation.instruments.length"
                          v-if="index3 == 0"
                          ><div
                            class="div-href"
                            @click="redirectToEvaluation(evaluation.id)"
                          >
                            {{ evaluation.name }}
                          </div>
                        </b-td>
                        <b-td
                          class="td-class"
                          :rowspan="evaluation.instruments.length"
                          v-if="index3 == 0"
                          v-bind:class="
                            evaluation.automated_grade.toFixed(1) == 0
                              ? ''
                              : evaluation.automated_grade.toFixed(1) <= 3.9
                              ? 'bg-red'
                              : evaluation.automated_grade.toFixed(1) <= 5.4
                              ? 'bg-yellow'
                              : 'bg-green'
                          "
                          >{{ evaluation.automated_grade.toFixed(1) }}</b-td
                        >
                        <b-td
                          class="td-class"
                          :rowspan="evaluation.instruments.length"
                          v-if="index3 == 0"
                          >{{ evaluation.obtained_score.toFixed(0) }}/<span
                            v-b-tooltip.v-secondary.noninteractive.hover="
                              'Puntaje máximo'
                            "
                          >
                            {{ evaluation.score }}
                          </span>
                        </b-td>
                        <b-td
                          class="td-class"
                          :rowspan="evaluation.instruments.length"
                          v-if="index3 == 0"
                          >{{ evaluation.weighing | Round }}%</b-td
                        >
                        <b-td class="td-class">{{ instrument.title }}</b-td>
                        <b-td
                          class="td-class"
                          v-bind:class="
                            instrument.automated_grade.toFixed(1) == 0
                              ? ''
                              : instrument.automated_grade.toFixed(1) <= 3.9
                              ? 'bg-red'
                              : instrument.automated_grade.toFixed(1) <= 5.4
                              ? 'bg-yellow'
                              : 'bg-green'
                          "
                          >{{ instrument.automated_grade.toFixed(1) }}</b-td
                        >
                        <b-td class="td-class"
                          >{{ instrument.obtained_score.toFixed(0) }}/<span
                            v-b-tooltip.v-secondary.noninteractive.hover="
                              'Puntaje máximo'
                            "
                          >
                            {{ instrument.score }}
                          </span>
                        </b-td>
                        <b-td class="td-class"
                          >{{ instrument.weighing | Round }}%</b-td
                        >
                      </b-tr>
                    </template>
                    <template v-else
                      ><b-tr :key="'Matter:' + index + 'Evaluation:' + index2">
                        <b-td
                          class="td-class"
                          :rowspan="rowspanCount(matter)"
                          v-if="index2 == 0"
                        >
                          <div
                            class="div-href"
                            @click="
                              redirectToMatterProgram(
                                matter.egress_profile_matter_id
                              )
                            "
                          >
                            {{ matter.matter_name }}
                          </div>
                        </b-td>
                        <b-td
                          class="td-class"
                          :rowspan="rowspanCount(matter)"
                          v-if="index2 == 0"
                          v-bind:class="
                            matter.section_grade.toFixed(1) == 0
                              ? ''
                              : matter.section_grade.toFixed(1) <= 3.9
                              ? 'bg-red'
                              : matter.section_grade.toFixed(1) <= 5.4
                              ? 'bg-yellow'
                              : 'bg-green'
                          "
                          >{{ matter.section_grade.toFixed(1) }}</b-td
                        >
                        <b-td class="td-class"
                          ><div
                            class="div-href"
                            @click="redirectToEvaluation(evaluation.id)"
                          >
                            {{ evaluation.name }}
                          </div></b-td
                        >
                        <b-td
                          class="td-class"
                          v-bind:class="
                            evaluation.automated_grade.toFixed(1) == 0
                              ? ''
                              : evaluation.automated_grade.toFixed(1) <= 3.9
                              ? 'bg-red'
                              : evaluation.automated_grade.toFixed(1) <= 5.4
                              ? 'bg-yellow'
                              : 'bg-green'
                          "
                          >{{ evaluation.automated_grade.toFixed(1) }}</b-td
                        >
                        <b-td class="td-class"
                          >{{ evaluation.obtained_score.toFixed(0) }}/<span
                            v-b-tooltip.v-secondary.noninteractive.hover="
                              'Puntaje máximo'
                            "
                          >
                            {{ evaluation.score }}
                          </span>
                        </b-td>
                        <b-td class="td-class"
                          >{{ evaluation.weighing | Round }}%</b-td
                        >
                        <b-td class="td-class">-</b-td>
                        <b-td class="td-class">-</b-td>
                        <b-td class="td-class">-</b-td>
                        <b-td class="td-class">-</b-td>
                      </b-tr>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr :key="'Matter:' + index">
                    <b-td class="td-class">
                      <div
                        class="div-href"
                        @click="
                          redirectToMatterProgram(
                            matter.egress_profile_matter_id
                          )
                        "
                      >
                        {{ matter.matter_name }}
                      </div>
                    </b-td>
                    <b-td
                      class="td-class"
                      v-bind:class="
                        matter.section_grade.toFixed(1) == 0
                          ? ''
                          : matter.section_grade.toFixed(1) <= 3.9
                          ? 'bg-red'
                          : matter.section_grade.toFixed(1) <= 5.4
                          ? 'bg-yellow'
                          : 'bg-green'
                      "
                      >{{ matter.section_grade.toFixed(1) }}</b-td
                    >
                    <b-td class="td-class">-</b-td>
                    <b-td class="td-class">-</b-td>
                    <b-td class="td-class">-</b-td>
                    <b-td class="td-class">-</b-td>
                    <b-td class="td-class">-</b-td>
                    <b-td class="td-class">-</b-td>
                    <b-td class="td-class">-</b-td>
                    <b-td class="td-class">-</b-td>
                  </b-tr>
                </template>
              </template>
            </template>
            <template v-else>
              <tr>
                <td colspan="10" class="td-class">No hay datos que mostrar</td>
              </tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewDashboard",
  components: {
    Card: () => import("./Card"),
    BoundedItemList: () => import("@/components/reusable/BoundedItemList"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    AssessmentTable: () =>
      import("@/components/dashboard/Assessment/AssessmentTable"),

    InstrumentCorrection: () =>
      import("@/components/users/DashboardCards/InstrumentCorrection"),
    NextTestTeacher: () =>
      import("@/components/users/DashboardCards/NextTestTeacher"),
    NextTestStudent: () =>
      import("@/components/users/DashboardCards/NextTestStudent"),
    CurrentSectionTeacher: () =>
      import("@/components/users/DashboardCards/CurrentSectionTeacher"),
    CurrentSectionStudent: () =>
      import("@/components/users/DashboardCards/CurrentSectionStudent"),
    NewInstrumentForm: () =>
      import("@/components/new-evaluations/Evaluation/NewInstrumentForm"),
    PublishTestStudent: () =>
      import("@/components/users/DashboardCards/PublishTestStudent"),
  },
  data() {
    return {
      my_class: [],
      next_evaluation_teacher: [],
      next_evaluation_teacher_notification: [],
      select_matter_notification_test: null,
      select_career_notification_test: null,
      select_section_correct_test: null,
      select_campus_notification_test: null,
      optionsMatterNotificationTest: [],
      optionsCareerNotificationTest: [],
      optionsSectionNotificationTest: [],
      optionsCampuNotificationTest: [],
      my_evaluation_scores: [],
      modal_header: "",
      key_notification: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      new_tests: names.NEW_TESTS,
    }),
    getPeriod() {
      if (!this.institution) return "";
      if (!this.institution.actual_period) return "";
      if (!this.institution.actual_period.name) return "";
      return this.institution.actual_period.name;
    },
    filterNextEvaluationNotification() {
      let list = this.next_evaluation_teacher_notification;
      if (this.select_campus_notification_test) {
        list = list.filter(
          (x) => x.campus_id == this.select_campus_notification_test
        );
      }
      if (this.select_career_notification_test) {
        list = list.filter(
          (x) => x.career_id == this.select_career_notification_test
        );
      }
      if (this.select_matter_notification_test) {
        list = list.filter(
          (x) => x.matter_id == this.select_matter_notification_test
        );
      }
      if (this.select_section_correct_test) {
        list = list.filter(
          (x) => x.section_id == this.select_section_correct_test
        );
      }
      return list;
    },
  },
  methods: {
    optionsMattersNotificationTest(mattersFromApi) {
      this.optionsMatterNotificationTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
      ];
      // Crear un Map para almacenar carreras únicas
      const uniqueMatters = new Map();
      // Iterar sobre los datos de la API y llenar el Map
      mattersFromApi.forEach((x) => {
        if (!uniqueMatters.has(x.matter_name)) {
          uniqueMatters.set(x.matter_name, x.matter_id);
        }
      });
      // Convertir el Map en un array de opciones y añadirlo a optionsMatterNotificationTest
      uniqueMatters.forEach((id, name) => {
        this.optionsMatterNotificationTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsCareersNotificationTest(careersFromApi) {
      this.optionsCareerNotificationTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.career",
            false,
            "Programa"
          )}`,
        },
      ];
      const uniqueCareers = new Map();
      careersFromApi.forEach((x) => {
        if (!uniqueCareers.has(x.career_name)) {
          uniqueCareers.set(x.career_name, x.career_id);
        }
      });
      uniqueCareers.forEach((id, name) => {
        this.optionsCareerNotificationTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsSectionsNotificationTest(sectionsFromApi) {
      this.optionsSectionNotificationTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}`,
        },
      ];
      const uniqueSections = new Map();
      sectionsFromApi.forEach((x) => {
        if (!uniqueSections.has(x.section_name)) {
          uniqueSections.set(x.section_name, x.section_id);
        }
      });
      uniqueSections.forEach((id, name) => {
        this.optionsSectionNotificationTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsCampusNotificationTest(campusFromApi) {
      this.optionsCampuNotificationTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.campus",
            false,
            "Sede"
          )}`,
        },
      ];
      const uniqueCampus = new Map();
      campusFromApi.forEach((x) => {
        if (!uniqueCampus.has(x.campus)) {
          uniqueCampus.set(x.campus, x.campus_id);
        }
      });
      uniqueCampus.forEach((id, name) => {
        this.optionsCampuNotificationTest.push({
          value: id,
          text: name,
        });
      });
    },
    redirectToTestTeacher(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "NewEvaluationView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    rowspanCount(matter) {
      let count = 0;
      matter.evaluations.forEach((evaluation) => {
        if (evaluation.instruments.length > 0)
          evaluation.instruments.forEach(() => {
            count += 1;
          });
        else count += 1;
      });
      return count;
    },
    modalSectionGrades(actual_grades) {
      if (!actual_grades) {
        this.modal_header = "( Completadas )";
        this.my_evaluation_scores = this.my_class.past_section_grades;
      } else {
        this.modal_header = "( Actuales )";
        this.my_evaluation_scores = this.my_class.current_section_grades;
        // [
        //   {
        //     matter_name: "Matemáticas",
        //     section_grade: 2,
        //     evaluations: [
        //       {
        //         name: "Eva1",
        //         obtained_score: 4,
        //         automated_grade: 4,
        //         score: 4,
        //         weighing: 30,
        //         instruments: [
        //           {
        //             title: "rubric 1",
        //             score: 7,
        //             obtained_score: 7,
        //             automated_grade: 7,
        //             weighing: 50,
        //           },
        //           {
        //             title: "rubric 2",
        //             score: 7,
        //             obtained_score: 7,
        //             automated_grade: 7,
        //             weighing: 50,
        //           },
        //         ],
        //       },
        //       {
        //         name: "Eva2",
        //         obtained_score: 4,
        //         automated_grade: 4,
        //         score: 4,
        //         weighing: 30,
        //         instruments: [
        //           {
        //             title: "rubric 1",
        //             score: 7,
        //             obtained_score: 7,
        //             automated_grade: 7,
        //             weighing: 50,
        //           },
        //         ],
        //       },
        //     ],
        //   },
        //   {
        //     matter_name: "Física",
        //     section_grade: 2,
        //     evaluations: [
        //       {
        //         name: "Eva 1",
        //         obtained_score: 4,
        //         automated_grade: 4,
        //         score: 4,
        //         weighing: 30,
        //         instruments: [
        //           {
        //             title: "rubric 1",
        //             score: 7,
        //             obtained_score: 7,
        //             automated_grade: 7,
        //             weighing: 50,
        //           },
        //           {
        //             title: "rubric 2",
        //             score: 7,
        //             obtained_score: 7,
        //             automated_grade: 7,
        //             weighing: 50,
        //           },
        //         ],
        //       },
        //     ],
        //   },
        //   {
        //     matter_name: "Salud Pública",
        //     section_grade: 2,
        //     evaluations: [
        //       {
        //         name: "Eva 2",
        //         obtained_score: 4,
        //         automated_grade: 4,
        //         score: 4,
        //         weighing: 30,
        //         instruments: [
        //           {
        //             title: "rubric 1",
        //             score: 7,
        //             obtained_score: 7,
        //             automated_grade: 7,
        //             weighing: 50,
        //           },
        //         ],
        //       },
        //       {
        //         name: "Eva 3",
        //         obtained_score: 4,
        //         automated_grade: 4,
        //         score: 4,
        //         weighing: 30,
        //         instruments: [],
        //       },
        //     ],
        //   },
        // ];
      }
      this.$bvModal.show("modal-history-evaluation");
    },
    redirectToMatterProgram(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "MatterProgramView",
        params: {
          matter_id: id,
        },
      });
      // window.open(route_data.href, "_blank");
    },
    redirectToNeedsGrading(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "NeedsGradingView",
        params: {
          section_id: id,
        },
      });
      // window.open(route_data.href, "_blank");
    },
    redirectToFullGrading(id) {
      this.$router.push({
        name: "FullGradingCenterView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToAssessment(id) {
      this.$router.push({
        name: "AssessmentView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToAssessmentReport(id) {
      this.$router.push({
        name: "AssessmentReportView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToSessionPlanning(ep_matter_id, section_id) {
      this.$router.push({
        name: "SessionPlanningView",
        params: {
          egress_profile_matter_id: ep_matter_id,
          section_id: section_id,
        },
      });
    },
    redirectToAchievementsPerCompetence() {
      this.$router.push({
        name: "AchievementsPerCompetenceView",
      });
    },
    redirectToEvaluationTable(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "EvaluationTableView",
        params: {
          matter_id: id,
        },
      });
      // window.open(route_data.href, "_blank");
    },
    redirectToEvaluation(id, instrument_id, instrument_type) {
      // let route_data = this.$router.resolve({
      if (instrument_id && instrument_type) {
        if (instrument_type == "question_test")
          this.$router.push({
            name: "EvaluationView",
            params: {
              evaluation_id: id,
              question_test_id: instrument_id,
            },
          });
        else if (instrument_type == "observation_instrument")
          this.$router.push({
            name: "EvaluationView",
            params: {
              evaluation_id: id,
              observation_instrument_id: instrument_id,
            },
          });
      } else
        this.$router.push({
          name: "EvaluationView",
          params: {
            evaluation_id: id,
          },
        });
      // window.open(route_data.href, "_blank");
    },
    patchStudentCard() {
      if (this.user.is_student) {
        if (this.user.student_card == null) {
          const payload = {
            show_dashboard_scores: false,
            user: this.user.id,
          };
          this.$restful.Post(`/cideu/student-card/${payload}`);
        } else {
          const payload = {
            show_dashboard_scores:
              !this.user.student_card.show_dashboard_scores,
          };
          this.$restful
            .Patch(`/cideu/student-card/${this.user.student_card.id}/`, payload)
            .then((response) => {
              this.user.student_card.show_dashboard_scores =
                response.show_dashboard_scores;
              this.$store.commit("setUser", this.user);
            });
        }
      }
    },
    getNextEvaluationTeacherNotification() {
      if (this.user && this.user.groups.includes(6)) {
        this.$restful
          .Get(`/evaluations2/dashboard_teacher_next_tests/?has_dates=${false}`)
          .then((response) => {
            this.next_evaluation_teacher_notification = response;
            this.optionsMattersNotificationTest(response);
            this.optionsCareersNotificationTest(response);
            this.optionsSectionsNotificationTest(response);
            this.optionsCampusNotificationTest(response);
            this.key_notification += 1;
            let test_ids = [...new Set(response.map((x) => x.evaluation_id))];
            this.$store.dispatch(names.FETCH_NEW_TESTS, {
              evaluations_ids: test_ids,
            });
            this.$store.dispatch(names.FETCH_NEW_TEST_TYPES);
          });
      }
    },
    changeInstrumentForm(test) {
      this.getNextEvaluationTeacherNotification();
      this.$bvModal.hide(`modal-edit-instrument-${test.id}`);
    },
    // getUserDashboard() {
    //   this.$restful.Get("/teaching/get_user_dashboard/").then((response) => {
    //     this.my_class = response;
    //   });
    // },
  },
  mounted() {
    // this.getUserDashboard();
    this.getNextEvaluationTeacherNotification();
  },
  watch: {
    user() {
      this.getNextEvaluationTeacherNotification();
    },
  },
  created() {
    this.$hasPermissions();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.container-title-my-classes {
  display: flex;
  justify-content: space-between;
}
.primary-cell-my-classes {
  vertical-align: middle;
  background-color: #80e98e;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-my-classes {
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.primary-cell-next-test {
  vertical-align: middle;
  background-color: #d8a899;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-next-test {
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.primary-cell-correction-test {
  vertical-align: middle;
  background-color: rgba(233, 117, 8, 0.3);
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-correction-test {
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.primary-cell-next-test-notification {
  vertical-align: middle;
  border: 1px solid black !important;
  background-color: #d8a899 !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-next-test-notification {
  text-align: center;
  border: 1px solid black;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.notification-dashboard {
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.container-matter-my-classes {
  justify-content: space-between;
  align-items: center;
}
.table-responsive {
  overflow-x: hidden;
  overflow-y: hidden;
}
.period-name {
  font-weight: bold;
}
.div-href-test {
  color: #007bff;
}
.notification-text {
  margin-top: 0.15rem;
  margin-left: 0.05rem;
}
.div-href-test:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.container-btn-three-dots {
  max-width: 250px;
}
.border-table {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.th-class {
  background-color: var(--primary-color);
  vertical-align: middle;
}
.td-class {
  vertical-align: middle;
}
.dashboard-container {
  display: flex;
}
.left-container {
  width: 80%;
}
.right-container {
  width: 20%;
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
.dashboard-card {
  display: block;
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 8px -1px #9d9d9d;
  /* box-shadow: 0px 1px 5px 0px #9d9d9d; */
  transition: all 0.5s ease-out;
}
/* .text-class:hover {
  background: #cbe2ec;
  border-radius: 4px;
} */
.btn-popover-my-classes {
  padding: 0.1rem 0.3rem;
  font-size: 11pt;
  border-radius: 4px;
}
.btn-three-dots {
  border-radius: 50%; /* Hace el botón redondo */
  padding: 2.5px 3px;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 0 2em;
  width: 100%;
  font-size: 11pt;
  text-align: center;
}
.card-header-icon {
  margin-top: 2px;
  margin-right: 0.1em;
}
.bookmark-icon {
  margin-top: 0.2em;
}
@media (max-width: 991px) {
  .dashboard-container {
    display: block !important;
  }
  .left-container,
  .right-container {
    width: 100%;
  }
}
.button-achievements {
  float: left;
  position: relative;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: var(--primary-font-size);
  font-family: inherit;
}

.button-achievements {
  width: 20rem;
  height: 2rem;
}

.button-achievements .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 2rem;
  background-color: var(--secondary-color);
  border-radius: 1.625rem;
}

.button-achievements .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.button-achievements .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.button-achievements .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.button-achievements .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.75rem 0;
  margin: -0.5rem 0 0 0;
  color: #282936;
  text-align: center;
}

.button-achievements:hover .circle {
  width: 100%;
}

.button-achievements:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.button-achievements:hover .button-text {
  color: #fff;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.text-break {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .container-select div {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .select-form {
    margin: 3px;
  }
}
@media (max-width: 576px) {
  .container-select div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .select-form {
    margin: 3px;
  }
}
</style>